// source: service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.gRPC.PriceRequest', null, global);
goog.exportSymbol('proto.gRPC.PriceResponse', null, global);
goog.exportSymbol('proto.gRPC.ReceiptList', null, global);
goog.exportSymbol('proto.gRPC.ReceiptList.Receipts', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gRPC.PriceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.gRPC.PriceRequest.repeatedFields_, null);
};
goog.inherits(proto.gRPC.PriceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gRPC.PriceRequest.displayName = 'proto.gRPC.PriceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gRPC.PriceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gRPC.PriceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gRPC.PriceResponse.displayName = 'proto.gRPC.PriceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gRPC.ReceiptList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.gRPC.ReceiptList.repeatedFields_, null);
};
goog.inherits(proto.gRPC.ReceiptList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gRPC.ReceiptList.displayName = 'proto.gRPC.ReceiptList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.gRPC.ReceiptList.Receipts = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.gRPC.ReceiptList.Receipts, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.gRPC.ReceiptList.Receipts.displayName = 'proto.gRPC.ReceiptList.Receipts';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.gRPC.PriceRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gRPC.PriceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.gRPC.PriceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gRPC.PriceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gRPC.PriceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pricesList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 1)) == null ? undefined : f,
    delivery: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    discount: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    promo: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    tip: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    freeDelivery: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gRPC.PriceRequest}
 */
proto.gRPC.PriceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gRPC.PriceRequest;
  return proto.gRPC.PriceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gRPC.PriceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gRPC.PriceRequest}
 */
proto.gRPC.PriceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPrices(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDelivery(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDiscount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPromo(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTip(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFreeDelivery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gRPC.PriceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gRPC.PriceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gRPC.PriceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gRPC.PriceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPricesList();
  if (f.length > 0) {
    writer.writePackedDouble(
      1,
      f
    );
  }
  f = message.getDelivery();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getDiscount();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getPromo();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getTip();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getFreeDelivery();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * repeated double prices = 1;
 * @return {!Array<number>}
 */
proto.gRPC.PriceRequest.prototype.getPricesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.gRPC.PriceRequest} returns this
 */
proto.gRPC.PriceRequest.prototype.setPricesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.gRPC.PriceRequest} returns this
 */
proto.gRPC.PriceRequest.prototype.addPrices = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.gRPC.PriceRequest} returns this
 */
proto.gRPC.PriceRequest.prototype.clearPricesList = function() {
  return this.setPricesList([]);
};


/**
 * optional double delivery = 2;
 * @return {number}
 */
proto.gRPC.PriceRequest.prototype.getDelivery = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.gRPC.PriceRequest} returns this
 */
proto.gRPC.PriceRequest.prototype.setDelivery = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double discount = 3;
 * @return {number}
 */
proto.gRPC.PriceRequest.prototype.getDiscount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.gRPC.PriceRequest} returns this
 */
proto.gRPC.PriceRequest.prototype.setDiscount = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double promo = 4;
 * @return {number}
 */
proto.gRPC.PriceRequest.prototype.getPromo = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.gRPC.PriceRequest} returns this
 */
proto.gRPC.PriceRequest.prototype.setPromo = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double tip = 5;
 * @return {number}
 */
proto.gRPC.PriceRequest.prototype.getTip = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.gRPC.PriceRequest} returns this
 */
proto.gRPC.PriceRequest.prototype.setTip = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional bool free_delivery = 6;
 * @return {boolean}
 */
proto.gRPC.PriceRequest.prototype.getFreeDelivery = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.gRPC.PriceRequest} returns this
 */
proto.gRPC.PriceRequest.prototype.setFreeDelivery = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gRPC.PriceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.gRPC.PriceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gRPC.PriceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gRPC.PriceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiptList: (f = msg.getReceiptList()) && proto.gRPC.ReceiptList.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gRPC.PriceResponse}
 */
proto.gRPC.PriceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gRPC.PriceResponse;
  return proto.gRPC.PriceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gRPC.PriceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gRPC.PriceResponse}
 */
proto.gRPC.PriceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.gRPC.ReceiptList;
      reader.readMessage(value,proto.gRPC.ReceiptList.deserializeBinaryFromReader);
      msg.setReceiptList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gRPC.PriceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gRPC.PriceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gRPC.PriceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gRPC.PriceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiptList();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.gRPC.ReceiptList.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReceiptList receipt_list = 1;
 * @return {?proto.gRPC.ReceiptList}
 */
proto.gRPC.PriceResponse.prototype.getReceiptList = function() {
  return /** @type{?proto.gRPC.ReceiptList} */ (
    jspb.Message.getWrapperField(this, proto.gRPC.ReceiptList, 1));
};


/**
 * @param {?proto.gRPC.ReceiptList|undefined} value
 * @return {!proto.gRPC.PriceResponse} returns this
*/
proto.gRPC.PriceResponse.prototype.setReceiptList = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.gRPC.PriceResponse} returns this
 */
proto.gRPC.PriceResponse.prototype.clearReceiptList = function() {
  return this.setReceiptList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.gRPC.PriceResponse.prototype.hasReceiptList = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.gRPC.ReceiptList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gRPC.ReceiptList.prototype.toObject = function(opt_includeInstance) {
  return proto.gRPC.ReceiptList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gRPC.ReceiptList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gRPC.ReceiptList.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiptsList: jspb.Message.toObjectList(msg.getReceiptsList(),
    proto.gRPC.ReceiptList.Receipts.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gRPC.ReceiptList}
 */
proto.gRPC.ReceiptList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gRPC.ReceiptList;
  return proto.gRPC.ReceiptList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gRPC.ReceiptList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gRPC.ReceiptList}
 */
proto.gRPC.ReceiptList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.gRPC.ReceiptList.Receipts;
      reader.readMessage(value,proto.gRPC.ReceiptList.Receipts.deserializeBinaryFromReader);
      msg.addReceipts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gRPC.ReceiptList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gRPC.ReceiptList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gRPC.ReceiptList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gRPC.ReceiptList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiptsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.gRPC.ReceiptList.Receipts.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.gRPC.ReceiptList.Receipts.prototype.toObject = function(opt_includeInstance) {
  return proto.gRPC.ReceiptList.Receipts.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.gRPC.ReceiptList.Receipts} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gRPC.ReceiptList.Receipts.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    subtotal: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    delivery: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    fee: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    taxes: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    deliveryDiscount: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    discount: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    promo: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    tip: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    total: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.gRPC.ReceiptList.Receipts}
 */
proto.gRPC.ReceiptList.Receipts.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.gRPC.ReceiptList.Receipts;
  return proto.gRPC.ReceiptList.Receipts.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.gRPC.ReceiptList.Receipts} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.gRPC.ReceiptList.Receipts}
 */
proto.gRPC.ReceiptList.Receipts.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSubtotal(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDelivery(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFee(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTaxes(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDeliveryDiscount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDiscount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPromo(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTip(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.gRPC.ReceiptList.Receipts.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.gRPC.ReceiptList.Receipts.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.gRPC.ReceiptList.Receipts} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.gRPC.ReceiptList.Receipts.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSubtotal();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getDelivery();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getFee();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getTaxes();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getDeliveryDiscount();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getDiscount();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getPromo();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getTip();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.gRPC.ReceiptList.Receipts.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.gRPC.ReceiptList.Receipts} returns this
 */
proto.gRPC.ReceiptList.Receipts.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double subtotal = 2;
 * @return {number}
 */
proto.gRPC.ReceiptList.Receipts.prototype.getSubtotal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.gRPC.ReceiptList.Receipts} returns this
 */
proto.gRPC.ReceiptList.Receipts.prototype.setSubtotal = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double delivery = 3;
 * @return {number}
 */
proto.gRPC.ReceiptList.Receipts.prototype.getDelivery = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.gRPC.ReceiptList.Receipts} returns this
 */
proto.gRPC.ReceiptList.Receipts.prototype.setDelivery = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double fee = 4;
 * @return {number}
 */
proto.gRPC.ReceiptList.Receipts.prototype.getFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.gRPC.ReceiptList.Receipts} returns this
 */
proto.gRPC.ReceiptList.Receipts.prototype.setFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double taxes = 5;
 * @return {number}
 */
proto.gRPC.ReceiptList.Receipts.prototype.getTaxes = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.gRPC.ReceiptList.Receipts} returns this
 */
proto.gRPC.ReceiptList.Receipts.prototype.setTaxes = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double delivery_discount = 6;
 * @return {number}
 */
proto.gRPC.ReceiptList.Receipts.prototype.getDeliveryDiscount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.gRPC.ReceiptList.Receipts} returns this
 */
proto.gRPC.ReceiptList.Receipts.prototype.setDeliveryDiscount = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double discount = 7;
 * @return {number}
 */
proto.gRPC.ReceiptList.Receipts.prototype.getDiscount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.gRPC.ReceiptList.Receipts} returns this
 */
proto.gRPC.ReceiptList.Receipts.prototype.setDiscount = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double promo = 8;
 * @return {number}
 */
proto.gRPC.ReceiptList.Receipts.prototype.getPromo = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.gRPC.ReceiptList.Receipts} returns this
 */
proto.gRPC.ReceiptList.Receipts.prototype.setPromo = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double tip = 9;
 * @return {number}
 */
proto.gRPC.ReceiptList.Receipts.prototype.getTip = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.gRPC.ReceiptList.Receipts} returns this
 */
proto.gRPC.ReceiptList.Receipts.prototype.setTip = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double total = 10;
 * @return {number}
 */
proto.gRPC.ReceiptList.Receipts.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.gRPC.ReceiptList.Receipts} returns this
 */
proto.gRPC.ReceiptList.Receipts.prototype.setTotal = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * repeated Receipts receipts = 1;
 * @return {!Array<!proto.gRPC.ReceiptList.Receipts>}
 */
proto.gRPC.ReceiptList.prototype.getReceiptsList = function() {
  return /** @type{!Array<!proto.gRPC.ReceiptList.Receipts>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.gRPC.ReceiptList.Receipts, 1));
};


/**
 * @param {!Array<!proto.gRPC.ReceiptList.Receipts>} value
 * @return {!proto.gRPC.ReceiptList} returns this
*/
proto.gRPC.ReceiptList.prototype.setReceiptsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.gRPC.ReceiptList.Receipts=} opt_value
 * @param {number=} opt_index
 * @return {!proto.gRPC.ReceiptList.Receipts}
 */
proto.gRPC.ReceiptList.prototype.addReceipts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.gRPC.ReceiptList.Receipts, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.gRPC.ReceiptList} returns this
 */
proto.gRPC.ReceiptList.prototype.clearReceiptsList = function() {
  return this.setReceiptsList([]);
};


goog.object.extend(exports, proto.gRPC);
