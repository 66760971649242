//npx webpack ./src/uber_splitter.js
// python -m http.server 8081
//eval `ssh-agent -s`
//ssh -i ~/.ssh/mainkey root@138.197.148.227
// 'http://138.197.148.227:8080'
import {PriceRequest,} from "../gRPC/service_pb";

const { SplitterServiceClient } = require("../gRPC/service_grpc_web_pb")
const client = new SplitterServiceClient('https://abdulkadir.me:8080', null, null);

// This is a neat Chrome extension that allows you to spy on grpc-web traffic just like you would on normal traffic.
// You can find it here: https://chrome.google.com/webstore/detail/grpc-web-developer-tools/ddamlpimmiapbcopeoifjfmoabdbfbjj?hl=en
const enableDevTools = window.__GRPCWEB_DEVTOOLS__ || (() => {});
enableDevTools([
    client,
]);

const currentDate = new Date().toDateString();

function removeElement(elementId) {
  // Removes an element from the document
    let element = document.getElementById(elementId);
    element.parentNode.removeChild(element);
}

function cloneTables(list){

    // update the `textContent` property of the `span` element with the `id` of `date`
    document.querySelector('#date').textContent = currentDate;

    let count = 0;
    for (let i of list){
        let arr = []
        // pushes the strings of the Receipts object into an empty array
        Object.keys(i).forEach(function(key) {
            if(key !== "id"){
                if(key === "deliveryDiscount" || key === "discount" || key === "promo" ){
                    let minus = "- " + i[key].toString()
                    arr.push(minus)
                }else{
                    arr.push(i[key].toString())
                }
            }
        });
        let index = count + 1;
        // update the `textContent` property of the `span` element with the `id` of `invoiceID`
        document.querySelector('#invoiceID').textContent = index.toString();

        for(let j =0; j < arr.length; j++) {
            let table = document.getElementById("tableData"),
                tr = table.getElementsByTagName('tr')[j],
                td = tr.getElementsByTagName('td')[1];
            td.innerHTML = arr[j];
        }

        count++;
        let myTable = document.getElementById("cloneID");
        let myClone = myTable.cloneNode(true);
        myClone.id = "table" + count.toString()
        myClone.style.display= null;
        document.body.appendChild(myClone);
    }
}


const myForm = document.getElementById("myForm");

myForm.addEventListener("submit", (e) => {
    if(e){
        e.preventDefault();
    }

    let arr =["prices_input" ,"delivery_input", "discount_input", "promotion_input", "tip_input"]
    let val = []
    for(let j =0; j < arr.length; j++) {
        val.push(document.getElementById(arr[j]).value)
    }
    val.push(document.getElementById('chk_box').checked)
    // var prices2 = document.getElementById("prices_input").value;
    // var delivery2 = document.getElementById("delivery_input").value;
    // var discount2 = document.getElementById("discount_input").value;
    // var promotion2 = document.getElementById("promotion_input").value;
    // var tip2 = document.getElementById("tip_input").value;
    // var freeDelivery2 = document.getElementById('chk_box').checked

    //get the prices from the first form
    let prices = val[0].split(',').map(function(item) {
        return parseFloat(item);
    });

    let request = new PriceRequest()

    request.setPricesList(prices)
    request.setDelivery(val[1])
    request.setDiscount(val[2])
    request.setPromo(val[3])
    request.setTip(val[4])
    request.setFreeDelivery(val[5])

    client.splitUberEats(request, {}, splitUberEatsCallback);
});


function splitUberEatsCallback(err, response) {
    console.log("made it!")
    if (err) {
        console.error(err);
        return;
    }
    let count = 1;
    while(1){
        console.log('in while, count: '+ count);
        let id = "table" + count.toString();
        console.log('id:'+ id);
        let table = document.getElementById(id);
        if (table != null){
            console.log('table is not null');
            removeElement(id)
        }else{
            console.log('table is null');
            break
        }
        count++
    }


    let obj = response.toObject()
    // var list = obj.receipts.receiptList
    let list = obj.receiptList.receiptsList
    cloneTables(list)
}
